import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Logo from '../components/logo';

const HowToInstallPage = () => {
	const downloadLink = 'https://bit.ly/3ad1b8f';

	return (
		<Layout>
			<SEO title="How to Install"/>

			{/* Logo */}
			<Logo/>

			{/* Upgrade */}
			<div
				className={'p-20 text-gray-300 w-full bg-gray-800 lg:p-10 rounded-lg'}
				id={'how-to-install-stocksolo'}>
				<h2>
					About
				</h2>
				<div>

					<b>
						If you like the plugin please consider giving us a 5 star review.
						<br/>
						<br/>
						Go to the “Plugins” menu at the top, select “Browse Plugins”, search for “StockSolo”, and scroll
						to the bottom of the plugin listing page.
					</b>
					<br/>
					<br/>

					<p>
						StockSolo is a free plugin for Photoshop, Illustrator, InDesign and XD developed by
						{' '}
						<a href={'https://redokun.com?utm_campaign=stocksolo&utm_medium=website'}>Redokun Srls</a>.
						{' '}
						We make a <a href={'https://redokun.com?utm_campaign=stocksolo&utm_medium=website'}>translation
						tool
						for InDesign</a>.
					</p>

					<p>
						Any issue with StockSolo? Send us an email: <a
						href={'mailto:info@stocksolo.com'}>info@stocksolo.com</a>.
						If you like it, please share it with your friends and coworkers,
						it means a lot to us.
					</p>

					<p>
						Unsplash, Pexels and Pixabay are online libraries of pictures.
						<br/>
						All photos published can be used for free and for commercial purposes.
						Permission to use the photos is not required but credits to the
						photographer are very appreciated.
					</p>

				</div>
			</div>
		</Layout>
	);
};

export default HowToInstallPage;
