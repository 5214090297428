import {Link} from 'gatsby';
import logo from '../images/stock-solo-logo-white.svg';
import styles from '../pages/index.module.css';
import React from 'react';

const Logo = () => {
  return <div className="py-10">
    <Link to={'/'}>
      <img src={logo} alt={'StockSolo'} className={`${styles.logo} mx-auto`}/>
    </Link>
  </div>;
};

export default Logo;
